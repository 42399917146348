import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class SetCurrentLinkGuard implements CanActivate {
  constructor(private globals: Globals) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const segments = state.url.split('/').slice(1);
    this.globals.currentLink = '/' + segments.join('/');
    this.globals.currentLinkArr = state.url.split('/');
    this.globals.baseRoute = state.url.split('/')[1];
    return true;
  }
}
