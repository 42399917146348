import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router, RouterModule
} from '@angular/router';
import { Globals } from '../globals';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private globals: Globals,
    private commonService: CommonService
  ) { }




  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentLink = state.url.split('/');
    const afterLoginRoutes = ['', 'my-interests', 'my-alerts', 'admin', 'review'];
    const loginRoute = '/auth/login';


    if (
      this.commonService.getCookie(this.globals.cookieLoginTokenName) !== null && this.commonService.getCookie(this.globals.cookieLoginTokenName) !== undefined
    ) {
      if (this.globals.authData == null || this.commonService.getCookie(this.globals.cookieUserDataName) == null) {
        this.commonService.getUserInfo().then(
          (res) => { },
          (error) => { }
        );
      }
      if (
        afterLoginRoutes.includes(currentLink[1])
      ) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    } else {
      if (
        afterLoginRoutes.includes(currentLink[1])
      ) {
        if (typeof window !== 'undefined') {
          window.location.href = this.globals.baseLoginUrl;
        }
        return false;
      } else {
        return true;
      }
    }
  }
}
