import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionGuard {
  constructor(private globals: Globals, private router: Router) { }

  // professionalRoutes = [
  //   '',
  //   'create-account',
  //   'forgot-password',
  //   'profile',
  //   'settings',
  //   'professional',
  //   'tickets',
  //   'payment-history'
  // ];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.authData) {
      // if (this.globals.isProfessional) {
      //   if (
      //     this.professionalRoutes.includes(
      //       this.globals.currentLink.split('/')[1]
      //     )
      //   ) {
      //     return true;
      //   } else {
      //     this.router.navigate(['/professional']);
      //     return false;
      //   }
      // } else {
      //   return true;
      // }
      return true;
    } else {
      return true;
    }
  }
}
